export const AccountValidationState = {
    NONE: 'none',
    VALIDATING: 'validating',
    WRONG_ID: 'wrongId',
    CORRECT_ID: 'correctId',
};

export const mapValidationStateToCSS = {
    [AccountValidationState.NONE]: '',
    [AccountValidationState.VALIDATING]: 'validating',
    [AccountValidationState.WRONG_ID]: 'wrong',
    [AccountValidationState.CORRECT_ID]: 'correct',
}


export const mapValidationStateToTitle = {
    [AccountValidationState.NONE]: '',
    [AccountValidationState.VALIDATING]: 'Ищем ID...',
    [AccountValidationState.WRONG_ID]: 'Неверный ID',
    [AccountValidationState.CORRECT_ID]: 'Верный ID',
}
