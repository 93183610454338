import React from 'react';
import './SuccessPage.css';
import {FcCheckmark} from "react-icons/fc";
import {Button} from "./Button";

export const SuccessPage = () => {
    return (
        <div className={'SuccessPage-container'}>
            <div className={'SuccessPage-header'}>
                <div className={'SuccessPage-checkmark'}>
                    <FcCheckmark
                        color={'#659C3A'}
                        size={25}
                    />
                </div>
                <h1 className={'SuccessPage-title'}>
                    Вы оформили<br/>премиум-подписку
                </h1>
            </div>
            <span className={'SuccessPage-subtitle'}>
                Подписка будет выдана автоматически на ваш аккаунт в приложении в течение 5-15 минут
            </span>
            <Button
                onClick={() => window.location.replace('https://anon.chat')}
                title={'Вернуться на главную страницу'}
            />
            <span className={'SuccessPage-subtitle-footer'}>
                Если у вас есть любые вопросы, обратитесь в техподдержку <a href={'https://anon.chat/app'}>anon.chat</a> или в техподдержку приложения через <a
                href={'https://t.me/anonchatsupport'}>чат в Телеграме</a>
            </span>
        </div>
    );
};
