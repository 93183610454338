import React from 'react';
import './FailPage.css';
import {MdOutlineClose} from "react-icons/md";
import {Button} from "./Button";

export const FailPage = () => {
    return (
        <div className={'FailPage-container'}>
            <div className={'FailPage-header'}>
                <div className={'FailPage-cross'}>
                    <MdOutlineClose
                        color={'#9C4C3A'}
                        size={25}
                    />
                </div>
                <h1 className={'FailPage-title'}>
                    Не удалось оформить<br/>премиум-подписку
                </h1>
            </div>
            <span className={'FailPage-subtitle'}>
                Что-то пошло не так. Попробуйте еще раз или свяжитесь с нами
            </span>
            <Button
                onClick={() => window.location.replace('https://anon.chat')}
                title={'Вернуться на главную страницу'}
            />
            <span className={'FailPage-subtitle-footer'}>
                Если у вас есть любые вопросы, обратитесь в техподдержку <a href={'https://anon.chat/app'}>anon.chat</a> или в техподдержку приложения через <a
                href={'https://t.me/anonchatsupport'}>чат в Телеграме</a>
            </span>
        </div>
    );
};
