import React from 'react';
import './App.css';
import {Outlet, useLocation} from "react-router-dom";

export const Navigator = () => {
    const location = useLocation();
    return (
        <div className={'App'}>
            <nav className={'Navigator-nav'}>
                <a href="https://anon.chat" className={'Navigator-a'}>
                    Главная
                </a>
                <a href="https://premium.anon.chat" className={`Navigator-a ${location.pathname === '/' && 'active'}`}>
                    Страница оплаты
                </a>
            </nav>
            <Outlet/>
        </div>
    );
};
