const card = {
    identifier: 'card',
    title: 'Картой',
    extra: 'Visa, MasterCard, МИР',
};

const yoomoney = {
    identifier: 'ym',
    title: 'YooMoney',
};

const qiwi = {
    identifier: 'qiwi',
    title: 'Qiwi',
};

const crypto = {
    // "crypto" isn't a real payment method but a workaround to show user list of payment methods
    // and yet have ability to distinguish it from mobile
    identifier: 'crypto',
    title: 'Криптовалюты',
};

const sbp = {
    identifier: 'sbp',
    title: 'СБП (Система Быстрых Платежей)',
}

const mobile = {
    // "mobile" isn't a real payment method but a workaround to show user list of payment methods
    // and yet have ability to distinguish it from crypto
    identifier: 'mobile',
    title: 'Мобильный счет (+40% комиссия)',
    extra: 'Мегафон, Теле2, Билайн',
}

export const paymentMethods = [card, mobile, crypto];
