import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {Navigator} from "./navigator";
import {SuccessPage} from "./components/SuccessPage";
import {FailPage} from "./components/FailPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigator/>}>
                    <Route index element={<App/>}/>
                    <Route path={'success'} element={<SuccessPage/>}/>
                    <Route path={'fail'} element={<FailPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
